<template>
  <div class="">
    <TopBarTitleComponent title="Contratos" :showBackIcon="true">
      <template slot="right-area">
        <div class="d-flex justify-content-end align-items-center">
          <ButtonPrimaryComponent
            title="Nuevo Contrato"
            @onSaveClick="goRouter('agreements.templates')"
          />
        </div>
      </template>
    </TopBarTitleComponent>

    <b-container fluid class="pb-4 px-lg-4">
      <template v-if="apiResponse.success">
        <template v-if="contracts.length">
          <section class="input-filters">
            <InputSearchComponent @onChangeSearch="onChangeSearch" />
            <SelectOrderByComponent @onSelectOrderBy="onSelectOrderBy" />
          </section>
          <paginate
            class="wrapper-pagination"
            name="resultQueryContracts"
            :list="resultQueryContracts"
            :per="perPaginate"
            :key="searchQuery"
          >
            <div class="card-content-items">
              <b-row>
                <b-col
                  xs="12"
                  sm="6"
                  md="6"
                  lg="4"
                  xl="3"
                  v-for="(contract, index) in paginated('resultQueryContracts')"
                  :key="index"
                >
                  <div
                    class="card card-shadow mb-4"
                    @click.stop="
                      goRouter('agreements.edit', { id: contract.id })
                    "
                  >
                    <div class="card-body card-shadow-body py-2">
                      <div class="px-2 pt-4">
                        <div class="agreements-description-item mb-3">
                          <h3 class="h3 font-bold text-center str-limit-space">
                            {{ contract.name }}
                          </h3>
                        </div>
                        <div class="agreements-description-item">
                          <div
                            class="d-flex justify-content-between align-items-center"
                          >
                            <small class="small"
                              >Creado: {{ contract.created_at }}</small
                            >

                            <DownloadContractComponent :contract="contract" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </paginate>
          <paginate-links
            v-show="resultQueryContracts.length > perPaginate"
            for="resultQueryContracts"
            :classes="{ ul: 'pagination', li: 'page-item', a: 'page-link' }"
            :simple="{
              next: 'Siguiente »',
              prev: '« Regresar'
            }"
          ></paginate-links>
        </template>
        <EmptyPage
          :media="emptyMedia4"
          text="No se han encontrado registros, empieza a crear tu lista de contratos para adjuntar a tus cotizaciones"
          v-else
        />
      </template>
      <SkeletonLoadingCard v-else />
    </b-container>
  </div>
</template>

<script>
import TopBarTitleComponent from "../../components/TopBarTitle";
import InputSearchComponent from "../../components/InputSearch";
import SelectOrderByComponent from "../../components/SelectOrderBy";
import ButtonPrimaryComponent from "../../components/ButtonPrimary";
import SkeletonLoadingCard from "@/components/Animations/Loading/SkeletonCard";
import EmptyPage from "../../components/EmptyPage";
import DownloadContractComponent from "../components/Download";

import ContractService from "../services/ContractService";
import { emptyMedia4 } from "@/services/resources";

export default {
  name: "ContractListPage",
  data: () => ({
    emptyMedia4,
    apiResponse: {
      success: false
    },
    searchQuery: null,
    orderByQuery: null,
    paginate: ["resultQueryContracts"],
    perPaginate: 4,
    contracts: []
  }),
  computed: {
    resultQueryContracts() {
      let contracts = this.contracts;
      return contracts
        .concat()
        .sort((a, b) => {
          switch (this.orderByQuery) {
            case "recent":
              return a.id < b.id ? 1 : -1;
            case "old":
              return a.id > b.id ? 1 : -1;
            case "a-z":
              return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
            case "z-a":
              return a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1;
          }
        })
        .filter(item =>
          this.searchQuery
            ? item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
            : item
        );
    }
  },
  methods: {
    toggleApiResponse(property, value) {
      this.apiResponse[property] = value;
    },
    goRouter(name, params = {}) {
      this.$router.push({ name, params });
    },
    onChangeSearch({ search }) {
      this.searchQuery = search;
    },
    onSelectOrderBy({ orderBy }) {
      this.orderByQuery = orderBy;
    },
    async getContracts() {
      try {
        let response = await ContractService.getContracts();
        const { success, data } = response.data;
        if (success) {
          this.contracts = data;
          this.toggleApiResponse("success", true);
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    TopBarTitleComponent,
    InputSearchComponent,
    SelectOrderByComponent,
    ButtonPrimaryComponent,
    SkeletonLoadingCard,
    EmptyPage,
    DownloadContractComponent
  },
  mounted() {
    this.getContracts();
  }
};
</script>

<style lang="scss" scoped>
.card-options-agreements {
  > div.card {
    width: 200px;
    cursor: pointer;
    .card-body {
      flex-direction: column;

      h4 {
        word-break: break-word;
      }
    }
  }
}
</style>
