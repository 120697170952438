import Api from "@/config/Api";

export default {
  getContractTemplates() {
    return Api().get("/contracts/templates");
  },
  getContracts() {
    return Api().get("/contracts");
  },
  getContractId(contractId) {
    return Api().get(`/contracts/${contractId}`);
  },
  addContracts(data) {
    return Api().post("/contracts", data);
  },
  updateContractId(data) {
    return Api().put(`/contracts/${data.id}`, data);
  },
  deleteContractId(data) {
    return Api().delete(`/contracts/${data.id}`);
  },
  downloadContractId(contractId) {
    return Api().get(`/contracts/${contractId}?download=download`, {
      responseType: "arraybuffer"
    });
  }
};
