<template>
  <button
    class="md__btn-link m-0 p-0"
    @click.stop="onDownloadContract()"
    v-if="contract"
  >
    <WrapperTooltipComponent content="Descargar">
      <i class="h5 el-icon-download font-bold"></i>
    </WrapperTooltipComponent>
  </button>
</template>

<script>
import WrapperTooltipComponent from "../../components/WrapperTooltip";
import ContractService from "../services/ContractService";
import { downloadFile } from "@/assets/utils/files";

export default {
  name: "DownloadContractComponent",
  methods: {
    async onDownloadContract() {
      const { id: contractId, name: contractName } = this.contract;
      try {
        this.$store.dispatch("toggleRequestLoading");
        let response = await ContractService.downloadContractId(
          contractId
        ).finally(() => {
          this.$store.dispatch("toggleRequestLoading");
        });
        const { data } = response;
        if (response) {
          downloadFile(data, contractName || "Contrato");
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    WrapperTooltipComponent
  },
  props: {
    contract: {
      default: null
    }
  }
};
</script>

<style></style>
